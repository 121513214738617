import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Icon from '../Icon'
import Link from '../Link'

const bem = new BEMHelper('button')

export default function Button({
  children,
  primary,
  secondary,
  tertiary,
  subtle,
  warning,
  dark,
  type,
  block,
  to,
  mailto,
  className,
  arrow,
  circle,
  compact,
  link,
  ...props
}) {
  const showArrow = arrow || (to && arrow !== false)
  const modifiers = {
    primary,
    secondary,
    tertiary,
    dark,
    subtle,
    warning,
    block,
    circle,
    link,
    compact,
    empty: !children,
    arrow: showArrow,
  }

  const arrowIcon = showArrow ? <Icon icon="chevron" {...bem('arrow')} /> : null

  if (mailto) {
    return (
      <a
        href={`mailto:${mailto}`}
        {...bem('', modifiers, className)}
        {...props}
      >
        {circle && <span {...bem('circle')}>{circle}</span>}
        {children}
        {arrowIcon}
      </a>
    )
  }

  if (to) {
    return (
      <Link to={to} {...bem('', modifiers, className)} {...props}>
        {circle && <span {...bem('circle')}>{circle}</span>}
        {children}
        {arrowIcon}
      </Link>
    )
  }

  return (
    <button type={type} {...bem('', modifiers, className)} {...props}>
      {circle && <span {...bem('circle')}>{circle}</span>}
      {children}
      {arrowIcon}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  arrow: PropTypes.bool,
  subtle: PropTypes.bool,
  warning: PropTypes.bool,
  dark: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  compact: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  circle: PropTypes.node,
  mailto: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
}
