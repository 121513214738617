import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

export default function Link ({ children, to, activeClassName, ...props }) {
  const internal = /^\/(?!\/)/.test(to)

  // TODO: If file:
  // const file = /\.[0-9a-z]+$/i.test(to)

  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...props}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  )
}

Link.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
}
