import 'mapbox-gl/dist/mapbox-gl.css'
import './index.scss'

import React, { Component } from 'react'
import BEMHelper from 'react-bem-helper'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Icon from '../Icon'

const config = require('../../../config.json')

const bem = new BEMHelper('map')

const TOGGLE_CONTROLS = [
  'scrollZoom',
  'boxZoom',
  // 'dragRotate',
  // 'dragPan',
  'keyboard',
  // 'doubleClickZoom',
  'touchZoomRotate',
]

export default class Map extends Component {
  static propTypes = {
    coordinates: PropTypes.array,
    zoom: PropTypes.number,
    title: PropTypes.string,
    preamble: PropTypes.string,
    controls: PropTypes.bool,
    centerPos: PropTypes.array,
  }

  static defaultProps = {
    zoom: 5,
    coordinates: [{ title: null, position: [0, 0] }],
  }

  _markers = []
  _popups = []
  _map = null

  componentDidMount () {
    this._mapboxgl = require('mapbox-gl')
    this._mapboxgl.accessToken = config.mapbox_access_token

    const { zoom, coordinates, centerPos } = this.props

    let lng = 0
    let lat = 0

    if (coordinates) {
      lng =
        coordinates.reduce((r, i) => r + i.position[0], 0) / coordinates.length
      lat =
        coordinates.reduce((r, i) => r + i.position[1], 0) / coordinates.length
    }

    if (centerPos && centerPos.length > 1) {
      lng = centerPos[0]
      lat = centerPos[1]
    }

    this._map = new this._mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [lng, lat + 1],
      pitch: 30,
      zoom,
    })

    if (!this.props.controls) {
      TOGGLE_CONTROLS.forEach(name => {
        this._map[name].disable()
      })
    }

    this.updateCoordinates()
  }

  updateCoordinates = () => {
    const { coordinates } = this.props

    if (coordinates) {
      coordinates.forEach(({ position, primary = false, image }, index) => {
        this._popup = new this._mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: primary ? 13 : 9,
          ...bem('popup', { primary, image }),
        })
          .setLngLat(position)
          .setDOMContent(this._popups[index])
          .addTo(this._map)

        // create the marker
        this._marker = new this._mapboxgl.Marker(this._markers[index])
          .setLngLat(position)
          .addTo(this._map)
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (this._map) {
      this._map.resize()

      if (
        JSON.stringify(prevProps.coordinates) !==
        JSON.stringify(this.props.coordinates)
      ) {
        this.updateCoordinates()
      }
    }

    if (this.props.controls) {
      TOGGLE_CONTROLS.forEach(name => {
        this._map[name].enable()
      })
    } else if (!this.props.controls) {
      TOGGLE_CONTROLS.forEach(name => {
        this._map[name].disable()
      })
    }
  }

  zoom = direction => () => {
    if (direction === 'in') {
      this._map.zoomIn()
    } else if (direction === 'out') {
      this._map.zoomOut()
    }
  }

  renderControls = () => {
    return (
      <nav {...bem('zoom')}>
        <button
          type="button"
          {...bem('zoom-button', 'plus')}
          onClick={this.zoom('in')}
        >
          <Icon icon="plus" />
        </button>
        <button
          type="button"
          {...bem('zoom-button', 'minus')}
          onClick={this.zoom('out')}
        >
          <Icon icon="minus" />
        </button>
      </nav>
    )
  }

  shouldComponentUpdate (nextProps) {
    return (
      JSON.stringify(nextProps.coordinates) !==
        JSON.stringify(this.props.coordinates) ||
      nextProps.controls !== this.props.controls
    )
  }

  order = (a, b) => b.position[1] - a.position[1]

  render () {
    const { coordinates, controls } = this.props

    return (
      <div {...bem('')}>
        {coordinates &&
          coordinates.sort(this.order).map((item, index) => (
            <div
              {...bem('marker')}
              ref={ref => {
                this._markers[index] = ref
              }}
              key={`marker-${index}`}
            />
          ))}

        {coordinates &&
          coordinates
            .sort(this.order)
            .map(({ title, preamble, primary, image }, index) => (
              <div
                ref={ref => {
                  this._popups[index] = ref
                }}
                key={`popup-${index}`}
                {...bem('popup-content', { image })}
              >
                {image && (
                  <Img fluid={image.fluid} alt={title} {...bem('image')} />
                )}
                <div>
                  {primary ? (
                    <h1
                      {...bem('title', {
                        long: title.length > 6 && title.length < 8,
                        xlong: title.length > 7,
                      })}
                    >
                      {title}
                    </h1>
                  ) : (
                    <h2 {...bem('title')}>{title}</h2>
                  )}
                  {preamble && <p {...bem('preamble')}>{preamble}</p>}
                </div>
              </div>
            ))}

        <div ref={el => (this.mapContainer = el)} {...bem('map')} />
        {controls && this.renderControls()}
      </div>
    )
  }
}
