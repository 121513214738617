/* eslint-disable complexity */

import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('icon')

const ICONS = {
  shipfront: [24, 31],
  search: [22, 22],
  check: [15, 10],
  flip: [25, 20],
  menu: [28, 28],
  arrow: [16, 13],
  chevron: [8, 13],
  calendar: [18, 18],
  x: [13, 13],
  plus: [10, 10],
  minus: [10, 2],
  info: [2, 9],
  link: [18, 18],
  boat: [32, 25],
  boatMedium: [50, 29],
  truck: [30, 20],
  door: [15, 30],
  port: [24, 46],
  portSmall: [24, 40],
  containers: [24, 24],
  like: [22, 22],
  train: [35, 18],
}

export default function Icon ({
  icon,
  direction,
  strong,
  thin,
  className,
  title,
}) {
  const [width, height] = ICONS[icon]
  const viewBox = strong
    ? `-1 -1 ${width + 2} ${height + 2}`
    : `0 0 ${width} ${height}`

  return (
    <svg
      width={`${strong ? Math.ceil(width * 1.46) : width}px`}
      height={`${strong ? Math.ceil(height * 1.46) : height}px`}
      viewBox={viewBox}
      {...bem(
        '',
        {
          strong,
          thin,
          [icon]: icon,
          [direction]: direction,
        },
        className,
      )}
    >
      {title && <title>{title}</title>}
      {icon === 'shipfront' && (
        <g>
          <polyline
            {...bem('line')}
            points="16.7,28 20.7,16.1 12,13.9 3.3,16.1 7.3,28"
          />
          <line {...bem('line')} x1="12" y1="15" x2="12" y2="29" />
          <polyline
            {...bem('line')}
            points="18.3,15.2 18.3,7.9 5.7,7.9 5.7,15.2"
          />
          <path
            {...bem('line')}
            d="M23,28.9c-1.2,1.2-3.2,1.2-4.4,0l0,0c-1.2-1.2-3.2-1.2-4.4,0l0,0c-1.2,1.2-3.2,1.2-4.4,0l0,0 c-1.2-1.2-3.2-1.2-4.4,0l0,0c-1.2,1.2-3.2,1.2-4.4,0"
          />
          <polyline {...bem('line')} points="14.1,7.6 14.1,1 10.1,1 10.1,7.6" />
        </g>
      )}
      {icon === 'search' && (
        <g>
          <circle {...bem('line')} cx="9.2" cy="9.2" r="8.2" />
          <line {...bem('line')} x1="20.8" y1="20.8" x2="15.1" y2="15.1" />
        </g>
      )}
      {icon === 'check' && (
        <g>
          <polyline {...bem('line')} points="1,5 5,9 13,1" />
        </g>
      )}
      {icon === 'flip' && (
        <g>
          <line {...bem('line')} x1="14.2" y1="6.5" x2="2.2" y2="6.5" />
          <polyline {...bem('line')} points="6.5,12 1,6.5 6.5,1" />
          <line {...bem('line')} x1="10.6" y1="13.5" x2="22.6" y2="13.5" />
          <polyline {...bem('line')} points="18.4,8 23.9,13.5 18.4,19" />
        </g>
      )}
      {icon === 'menu' && (
        <g>
          <line {...bem('line')} x1="1" y1="5" x2="27" y2="5" />
          <line {...bem('line')} x1="1" y1="14" x2="27" y2="14" />
          <line {...bem('line')} x1="1" y1="23" x2="27" y2="23" />
        </g>
      )}
      {icon === 'arrow' && (
        <g>
          <line {...bem('line', 'square')} x1="1" y1="6.5" x2="14" y2="6.5" />
          <polyline {...bem('line', 'square')} points="9.5,1 15,6.5 9.5,12" />
        </g>
      )}
      {icon === 'chevron' && (
        <g>
          <polyline {...bem('line', 'square')} points="1,1 6.5,6.5 1,12" />
        </g>
      )}
      {icon === 'calendar' && (
        <g>
          <rect {...bem('line')} x="1" y="1" width="16" height="4" />
          <line {...bem('line', 'square')} x1="4" y1="9" x2="6" y2="9" />
          <line {...bem('line', 'square')} x1="8" y1="9" x2="10" y2="9" />
          <line {...bem('line', 'square')} x1="12" y1="9" x2="14" y2="9" />
          <line {...bem('line', 'square')} x1="4" y1="13" x2="6" y2="13" />
          <line {...bem('line', 'square')} x1="8" y1="13" x2="10" y2="13" />
          <line {...bem('line', 'square')} x1="12" y1="13" x2="14" y2="13" />
          <polyline {...bem('line')} points="1,5 1,17 17,17 17,5" />
        </g>
      )}
      {icon === 'plus' && (
        <g>
          <line {...bem('line')} x1="1" y1="5" x2="9" y2="5" />
          <line {...bem('line')} x1="5" y1="1" x2="5" y2="9" />
        </g>
      )}
      {icon === 'minus' && (
        <g>
          <line {...bem('line')} x1="1" y1="1" x2="9" y2="1" />
        </g>
      )}
      {icon === 'info' && (
        <g>
          <path {...bem('fill')} d="M0,0h2v2H0V0z M0,3h2v6H0V3z" />
        </g>
      )}
      {icon === 'x' && (
        <g>
          <polyline {...bem('line')} points="1,1 6.5,6.5 1,12" />
          <polyline {...bem('line')} points="12,12 6.5,6.5 12,1" />
        </g>
      )}
      {icon === 'link' && (
        <g>
          <circle {...bem('line')} cx="9" cy="3.4" r="2.4" />
          <line {...bem('line')} x1="9" y1="17" x2="9" y2="5.8" />
          <path {...bem('line')} d="M3.4,9H1c0,4.4,3.6,8,8,8s8-3.6,8-8h-2.4" />
        </g>
      )}
      {icon === 'boat' && (
        <g>
          <polyline
            {...bem('line', 'square')}
            points="10.7,18 10.7,5 4.7,5 4.7,14 "
          />
          <rect
            {...bem('line', 'square')}
            x="13.7"
            y="7"
            width="12"
            height="5"
          />
          <polyline
            {...bem('line', 'square')}
            points="25.5,22.8 30.3,12.6 22.1,13.8 20.6,18 7.1,18 6,14 2.6,14 4.8,22.3 "
          />
          <path
            {...bem('line', ['square', 'wave'])}
            d="M30.7,23.1L30.7,23.1c-1.2,1.1-3.1,1.1-4.3,0l0,0c-1.2-1.1-3.1-1.1-4.3,0l0,0c-1.2,1.1-3.1,1.1-4.3,0l0,0 c-1.2-1.1-3.1-1.1-4.3,0l0,0c-1.2,1.1-3.1,1.1-4.3,0l0,0C8.1,22,6.2,22,5,23.1l0,0c-1.2,1.1-3.1,1.1-4.3,0"
          />
          <polyline
            {...bem('line', 'square')}
            points="9.7,5 9.7,1 5.7,1 5.7,5 "
          />
          <polyline
            {...bem('line', 'square')}
            points="25.7,14 25.7,12 13.7,12 13.7,17 "
          />
        </g>
      )}
      {icon === 'boatMedium' && (
        <g>
          <polyline
            {...bem('line')}
            points="17.5,20.7 17.5,6.9 6.8,6.9 6.8,15.2 "
          />
          <rect {...bem('line')} x="10.5" y="1" width="2.9" height="5.9" />
          <rect {...bem('line')} x="21" y="15.6" width="9" height="5.1" />
          <rect {...bem('line')} x="21" y="10.5" width="9" height="5.1" />
          <polyline
            {...bem('line')}
            points="36.1,15.6 29.9,15.6 29.9,10.5 38.9,10.5 38.9,14.8 "
          />
          <polyline
            {...bem('line')}
            points="42.5,25.5 47,13.5 36.3,15.2 34.3,20.7 10.3,20.7 9.2,15.2 4.3,15.2 6.5,25.7"
          />
          <path
            {...bem('line', 'square')}
            d="M48.2,26.2L48.2,26.2c-1.1,1.1-3.1,1.1-4.2,0l0,0c-1.1-1.1-3.1-1.1-4.2,0l0,0c-1.1,1.1-3.1,1.1-4.2,0l0,0 c-1.1-1.1-3.1-1.1-4.3,0l0,0c-1.1,1.1-3.1,1.1-4.2,0l0,0c-1.1-1.1-3.1-1.1-4.2,0l0,0c-1.1,1.1-3.1,1.1-4.2,0l0,0 c-1.1-1.1-3.1-1.1-4.2,0l0,0c-1.1,1.1-3.1,1.1-4.2,0l0,0c-1.1-1.1-3.1-1.1-4.2,0l0,0c-1.1,1.1-3.1,1.1-4.2,0"
          />
        </g>
      )}
      {icon === 'truck' && (
        <g>
          <polygon {...bem('line')} points="6.1,1 22.6,1 21.6,13.6 5.1,13.6" />
          <polygon
            {...bem('line')}
            points="22.2,5.9 26.5,5.9 29,10.9 27.6,13.6 21.6,13.6"
          />
          <circle {...bem('line')} cx="10.5" cy="16" r="2.4" />
          <circle {...bem('line')} cx="23" cy="16" r="2.4" />
          <line
            {...bem('line', 'square')}
            x1="27.9"
            y1="18.7"
            x2="1"
            y2="18.7"
          />
        </g>
      )}
      {icon === 'door' && (
        <g>
          <polyline
            {...bem('line', 'square')}
            points="13.6,22.3 13.6,1 1,1 1,22.3"
          />
          <polygon {...bem('line')} points="8.9,7.7 1,1 1,22.3 8.9,29 " />
          <line {...bem('line')} x1="6.4" y1="16.8" x2="6.4" y2="17.2" />
        </g>
      )}
      {icon === 'port' && (
        <g>
          <rect {...bem('line')} x="1" y="21" width="22" height="12" />
          <line {...bem('line', 'square')} x1="23" y1="45" x2="1" y2="45" />
          <line {...bem('line', 'square')} x1="14" y1="31" x2="14" y2="23" />
          <line {...bem('line', 'square')} x1="18" y1="31" x2="18" y2="23" />
          <line {...bem('line', 'square')} x1="10" y1="31" x2="10" y2="23" />
          <line {...bem('line', 'square')} x1="6" y1="31" x2="6" y2="23" />
          <path
            {...bem('line', 'square')}
            d="M14.4,14.5c0,1.4-1.2,2.5-2.7,2.4c-1.1-0.1-2-1-2.1-2.1c-0.1-1.5,1-2.7,2.4-2.7L12,0"
          />

          <line {...bem('line')} x1="5.4" y1="20.8" x2="10.6" y2="16.8" />
          <line {...bem('line')} x1="18.7" y1="20.8" x2="13.5" y2="16.8" />
        </g>
      )}
      {icon === 'portSmall' && (
        <g>
          <rect {...bem('line')} x="1" y="21" width="22" height="12" />
          <line {...bem('line', 'square')} x1="14" y1="31" x2="14" y2="23" />
          <line {...bem('line', 'square')} x1="18" y1="31" x2="18" y2="23" />
          <line {...bem('line', 'square')} x1="10" y1="31" x2="10" y2="23" />
          <line {...bem('line', 'square')} x1="6" y1="31" x2="6" y2="23" />
          <path
            {...bem('line', 'square')}
            d="M14.4,14.5c0,1.4-1.2,2.5-2.7,2.4c-1.1-0.1-2-1-2.1-2.1c-0.1-1.5,1-2.7,2.4-2.7L12,0"
          />

          <line {...bem('line')} x1="5.4" y1="20.8" x2="10.6" y2="16.8" />
          <line {...bem('line')} x1="18.7" y1="20.8" x2="13.5" y2="16.8" />
        </g>
      )}
      {icon === 'containers' && (
        <g>
          <rect {...bem('line')} x="1" y="12" width="20" height="11" />
          <line {...bem('line', 'square')} x1="13" y1="21" x2="13" y2="14" />
          <line {...bem('line', 'square')} x1="17" y1="21" x2="17" y2="14" />
          <line {...bem('line', 'square')} x1="9" y1="21" x2="9" y2="14" />
          <line {...bem('line', 'square')} x1="5" y1="21" x2="5" y2="14" />
          <rect {...bem('line')} x="3" y="1" width="20" height="11" />
          <line {...bem('line', 'square')} x1="15" y1="10" x2="15" y2="3" />
          <line {...bem('line', 'square')} x1="19" y1="10" x2="19" y2="3" />
          <line {...bem('line', 'square')} x1="11" y1="10" x2="11" y2="3" />
          <line {...bem('line', 'square')} x1="7" y1="10" x2="7" y2="3" />
        </g>
      )}
      {icon === 'like' && (
        <g>
          <path
            {...bem('line')}
            d="M13,8V4c0-1.7-1.3-3-3-3l-4,9v11h11.3c1,0,1.9-0.7,2-1.7l1.4-9C20.8,9.2,20.1,8.2,19,8c-0.1,0-0.2,0-0.3,0L13,8 z M6,21H2.9C1.9,21,1,20.1,1,19.1v-7.1C1,10.9,1.9,10,2.9,10H6"
          />
        </g>
      )}
      {icon === 'train' && (
        <g>
          <line {...bem('line', 'square')} x1="32" y1="16" x2="4" y2="16" />
          <line {...bem('line')} x1="32" y1="5" x2="28" y2="5" />
          <rect {...bem('line')} x="10" y="1" width="6" height="10" />
          <path
            {...bem('line')}
            d="M19,11h12.6c1.3,0,2.2-1.2,2-2.4l-1.2-6c-0.2-0.9-1-1.6-2-1.6H19V11z"
          />
          <rect {...bem('line')} x="1" y="1" width="6" height="10" />
          <line {...bem('line', 'square')} x1="28" y1="14" x2="28" y2="18" />
          <line {...bem('line', 'square')} x1="23" y1="14" x2="23" y2="18" />
          <line {...bem('line', 'square')} x1="18" y1="14" x2="18" y2="18" />
          <line {...bem('line', 'square')} x1="13" y1="14" x2="13" y2="18" />
          <line {...bem('line', 'square')} x1="8" y1="14" x2="8" y2="18" />
        </g>
      )}
    </svg>
  )
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(ICONS)).isRequired,
  color: PropTypes.string,
  direction: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  strong: PropTypes.bool,
  thin: PropTypes.bool,
}
